<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        HWA
      </h2>
    </b-link>
    <div
      class="misc-inner p-2 p-sm-3"
    >
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Verifying Email...
        </h2>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store'

export default {
  components: {
    VuexyLogo,
    BLink,
  },
  data() {
    return {
      loading: false,
      verificationInProgress: false,
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
    this.verifyEmail()
  },
  methods: {
    verifyEmail() {
      this.verificationInProgress = true
      this.$http
        .post('/api/auth/verify', { id: this.$route.query.id, hash: this.$route.query.hash })
        .then(res => {
          this.$bvToast.toast('Email verified Successfully', {
            title: 'Verification',
            variant: 'success',
            solid: true,
          })
          this.$store.commit('auth/setUserVerified')
          this.$router.push({ name: 'login' })
          this.verificationInProgress = false
        })
        .catch(err => {
          this.verificationInProgress = false
          this.$router.push({ name: 'login' })
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-misc.scss';
</style>
